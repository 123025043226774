<script>
  import userService from "../services/user-service.js";
  import { navigateTo } from "svelte-router-spa";
  import { loggedIn, user } from "../stores/user-store.js";

  userService.clear();
  user.set(null);
  navigateTo("/");
</script>

<style>

</style>

<h1>Logout Callback View</h1>
