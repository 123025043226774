<script>
  import data from "../models/products.js";
  import { items } from "../stores/menu-store.js";
  import Slider from "../components/slider/slider.svelte";
  import Toggle from "../components/toggle/toggle.svelte";
  import Button from "../components/button.svelte";

  let dataType = "posters";

  const ops = {
    PRICE: "base",
    MUL: "mul",
    ADD: "add",
  };

  const views = {
    FORM: "form",
    COMPLETE: "complete",
  };

  let activeView = views.FORM;

  let details = {
    firstName: "",
    lastName: "",
    email: "",
  };
  let busy = false;
  let selection = { base: 0, tax: 0.15, quantity: 0, pages: 0 };
  let price = { tax: 0, unit: 0, product: 0, total: 0 };
  let sliders = {};

  function handleChange($event) {
    if (!$event.detail.value) return;

    if ($event.detail.name === "quantity" || $event.detail.name === "pages")
      handleQuantity($event);
    else {
      switch ($event.detail.data.op) {
        case ops.ADD:
          handleAddition($event);
          break;
        case ops.MUL:
          handleMultiply($event);
          break;
        case ops.PRICE:
          handlePrice($event);
          break;
        default:
          break;
      }
    }

    calculateUnitCost();
    calculateProductCost();
    calculateTax();
    calculateTotal();
  }

  function handleQuantity($event) {
    selection[$event.detail.name] = $event.detail.value;
  }

  function handleAddition($event) {
    const slider =
      $event.detail.data.min !== undefined &&
      $event.detail.data.max !== undefined;
    selection[ops.ADD] = selection[ops.ADD] || {};
    selection[ops.ADD][$event.detail.name] = {
      value: $event.detail.data.value,
      key: $event.detail.data.text,
      units: slider ? $event.detail.value : 1,
    };
    sliders[$event.detail.name] = {
      show: slider,
      item: $event.detail.data,
    };
  }

  function handleMultiply($event) {
    selection[ops.MUL] = selection[ops.MUL] || {};
    selection[ops.MUL][$event.detail.name] = {
      value: $event.detail.data.value,
      key: $event.detail.data.text,
    };
  }

  function handlePrice($event) {
    selection.base = {
      value: $event.detail.data.value,
      key: $event.detail.data.text,
    };
  }

  function calculateUnitCost() {
    if (!selection[ops.MUL]) return;

    if (data[dataType].pages === undefined) selection.pages = 1;
    let unitPrice = selection.base.value * selection.pages;

    price.unit = [unitPrice]
      .concat(
        Object.keys(selection[ops.MUL]).map((d) => selection[ops.MUL][d].value)
      )
      .reduce((accumulator, current) => accumulator * current);

    if (!selection[ops.ADD]) return;

    price.unit = [price.unit]
      .concat(
        Object.keys(selection[ops.ADD]).map(
          (d) => selection[ops.ADD][d].value * selection[ops.ADD][d].units
        )
      )
      .reduce((accumulator, current) => accumulator + current);
  }

  function calculateProductCost() {
    price.product = price.unit * selection.quantity;
  }

  function calculateTax() {
    price.tax = price.product * selection.tax;
  }

  function calculateTotal() {
    price.total = price.product + price.tax;
  }

  function displayCurrency(n) {
    return `ZAR ${(n / 100).toFixed(2)}`;
  }

  async function submitQuote($event) {
    $event.stopPropagation();
    $event.preventDefault();

    if (busy) return;
    busy = true;

    grecaptcha.ready(function () {
      grecaptcha
        .execute("6LcuDsIZAAAAAEb6HpGRHVCKJeualxC-umOTSzYp", {
          action: "submit",
        })
        .then(function (token) {
          const payload = {
            selection: selection,
            price: price,
            details: details,
            type: "Poster"
          };

          sendPayload(token, payload);
        })
        .catch((err) => {
          console.error(err);
          busy = false;
        });
    });
  }

  async function sendPayload(token, payload) {
    try {
      // const uri = `https://localhost:44385/api/quote?token=${token}`;
      const uri = `https://doubleimage.co.za/api/quote?token=${token}`;

      const response = await fetch(uri, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
        mode: "no-cors",
      });
      activeView = views.COMPLETE;
      busy = false;
    } catch (err) {
      console.error(err);
      busy = false;
    }
  }

  function handleSent() {
    activeView = views.FORM;
  }
</script>

<style>
  .max-height {
    max-height: 400px;
  }
  @media (max-width: 768px) {
    .max-height {
      max-height: none;
    }
  }
</style>

<div class="bg-gray-300">
  <section class="py-8 container mx-auto px-4">

    <div
      class="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-4 justify-items-center">
      <div>
        <img
          class="max-height mx-auto"
          src="assets/product/posters/Posters_640X480_merged.png" />
      </div>
      <div>
        <h2 class="py-8 text-4xl font-semi-bold text-center sm:text-left">
          Poster
        </h2>
        <p>Get your message out there with our high-resolution posters.</p>
        <ul class="list-marked mt-4">
          <li>Available in A3, A2, A1, A0 sizes</li>
          <li>
            Full colour, single or double-sided high-resolution digital printing
          </li>
          <li>Various grammages of paper are available</li>
          <li>Not suitable for outdoor use</li>
        </ul>
      </div>
    </div>

  </section>
</div>
<div class="bg-white">
  <section class="py-8 container mx-auto px-4">
    <h2 class="py-8 text-4xl font-semi-bold">Specifications</h2>
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 justify-items-center">
      <div>
        <ul class="list-marked">
          <li>The source file must be set to the size of the print.</li>
          <li>Images must be a minimum of 300dpi</li>
          <li>
            When using Pantone colours, use
            <strong>uncoated</strong>
            Pantone colours
          </li>
          <li>Images must be CMYK. All black text to be 100% K (black)</li>
          <li>All files must be saved in PDF format</li>
          <li>
            Fonts and links must be embedded in the PDF file or the fonts
            converted to curves
          </li>
          <li>PDFs must be supplied 1-up centred on the page.</li>
          <li>Multiple pages should be provided as a multi-page PDF</li>
        </ul>
      </div>
      <div>
        <img class="max-height mx-auto" src="assets/product/posters/tmpl.svg" />
      </div>
    </div>
  </section>
</div>

<div class="bg-gray-300">
  {#if activeView === views.FORM}
    <section class="py-8 container mx-auto px-4">
      <h2 class="py-4 text-4xl font-semi-bold">Quotation</h2>
      <p class="mb-8">
        Fill out the form below to get a quotation for your order.
      </p>

      <form on:submit={submitQuote}>
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <h3 class="text-xl font-semi-bold text-center sm:text-left mb-4">
              Select Size
            </h3>

            {#each data[dataType].size as item}
              <div class="mb-2">
                <Toggle
                  name="size"
                  exclusive={true}
                  label={item.text}
                  value={item.default}
                  data={item}
                  on:change={handleChange} />
              </div>
            {/each}
          </div>
          <div>
            <h3
              class="mt-8 sm:mt-0 text-xl font-semi-bold text-center
              sm:text-left mb-4">
              Select Colours
            </h3>
            {#each data[dataType].colour as item}
              <div class="mb-2">
                <Toggle
                  name="colour"
                  exclusive={true}
                  label={item.text}
                  value={item.default}
                  data={item}
                  on:change={handleChange} />
              </div>
            {/each}
          </div>
          <div>
            <h3
              class="mt-8 sm:mt-0 text-xl font-semi-bold text-center
              sm:text-left mb-4">
              Select Material
            </h3>
            {#each data[dataType].material as item}
              <div class="mb-2">
                <Toggle
                  name="material"
                  exclusive={true}
                  label={item.text}
                  value={item.default}
                  data={item}
                  on:change={handleChange} />
              </div>
            {/each}
          </div>

          <div class="sm:col-span-2">
            <h3
              class="mt-8 sm:mt-0 text-xl font-semi-bold text-center
              sm:text-left mb-4">
              Select Number of Posters
            </h3>
            <label class="flex items-center space-x-4">
              <Slider
                min={data[dataType].quantity.min}
                max={data[dataType].quantity.max}
                step={data[dataType].quantity.step}
                on:change={handleChange}
                data={data[dataType].quantity}
                name="quantity" />
            </label>
          </div>
        </div>

        <h3 class="mt-8 text-2xl font-semi-bold text-center sm:text-left mb-4">
          Contact information
        </h3>
        <div>
          <div class="mb-4">
            <label
              class="block text-gray-700 text-sm font-bold mb-2"
              for="firstName">
              First name
            </label>
            <input
              class="shadow appearance-none border rounded w-full py-2 px-3
              text-gray-700 leading-tight focus:outline-none
              focus:shadow-outline"
              id="firstName"
              type="text"
              placeholder="First name"
              required="true"
              bind:value={details.firstName} />
          </div>

          <div class="mb-4">
            <label
              class="block text-gray-700 text-sm font-bold mb-2"
              for="lastName">
              Last name
            </label>
            <input
              class="shadow appearance-none border rounded w-full py-2 px-3
              text-gray-700 leading-tight focus:outline-none
              focus:shadow-outline"
              id="lastName"
              type="text"
              placeholder="Last name"
              required="true"
              bind:value={details.lastName} />
          </div>

          <div class="mb-4">
            <label
              class="block text-gray-700 text-sm font-bold mb-2"
              for="email">
              Email
            </label>
            <input
              class="shadow appearance-none border rounded w-full py-2 px-3
              text-gray-700 leading-tight focus:outline-none
              focus:shadow-outline"
              id="email"
              type="email"
              placeholder="Email"
              required="true"
              bind:value={details.email} />
          </div>
        </div>

        <h3 class="mt-8 text-2xl font-semi-bold text-center sm:text-left mb-4">
          Quotation details
        </h3>
        <div>
          <table class="w-full">
            <tr class="border-b border-mine-shaft-300">
              <td>
                <h4>Unit</h4>
              </td>
              <td class="text-right pl-4">
                <h4>{selection.quantity} x {displayCurrency(price.unit)}</h4>
              </td>
            </tr>
            <tr class="border-b border-mine-shaft-300">
              <td>
                <h4>Product</h4>
              </td>
              <td class="text-right pl-4">
                <h4>{displayCurrency(price.product)}</h4>
              </td>
            </tr>
            <tr class="border-b border-mine-shaft-300">
              <td>
                <h4>Tax ({selection.tax * 100}%)</h4>
              </td>
              <td class="text-right pl-4">
                <h4>{displayCurrency(price.tax)}</h4>
              </td>
            </tr>
            <tr class="border-b border-mine-shaft-300">
              <td>
                <h4>Total</h4>
              </td>
              <td class="text-right pl-4">
                <h4>{displayCurrency(price.total)}</h4>
              </td>
            </tr>
          </table>
        </div>

        <div class="mt-8 text-right">
          <Button isSubmit={true}>
            {busy ? 'Please wait ...' : 'Submit quote'}
          </Button>
        </div>

      </form>
    </section>
  {:else if activeView === views.COMPLETE}
    <section class="py-8 container mx-auto px-4 text-center">
      <p class="mt-4 mb-8">
        Your request has been sent. We'll be in touch shortly.
      </p>
      <Button on:click={handleSent}>Ok</Button>
    </section>
  {/if}
</div>
