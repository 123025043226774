<script>
  import { createEventDispatcher } from "svelte";
  export let styles;
  export let isSubmit = false;

  styles = !styles
    ? "bg-lemon hover:bg-brilliant-rose text-mine-shaft hover:text-white border-lemon hover:border-brilliant-rose"
    : styles;

  const dispatch = createEventDispatcher();

  function handleClick(event) {
    dispatch("click", event);
  }
</script>

<style>
  button {
    transition: all 0.3s ease-in-out;
  }
</style>

<button
  class="rounded font-bold px-2 py-1 uppercase tracking-wider border-2 mb-2 {styles}"
  on:click={handleClick}
  type={isSubmit ? 'submit' : 'button'}>
  <slot />
</button>
