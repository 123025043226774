import Dashboard from './views/dashboard.svelte';
import Upload from './views/upload.svelte';
import BusinessCardsInfo from './views/business-cards.svelte';
import HighVolumeCopyingInfo from './views/copying.svelte';
import FlyersInfo from './views/flyers.svelte';
import TrainingManualsInfo from './views/manuals.svelte';
import PostersInfo from './views/posters.svelte';
import LoginCallback from './views/login-cb.svelte';
import LogoutCallback from './views/logout-cb.svelte';
import BaseLayout from './layouts/base-layout.svelte';
import PageLayout from './layouts/page-layout.svelte';

import { user } from "./stores/user-store.js";

let isAdmin = false;

user.subscribe(user$ => {
    if (!user$) return;
    isAdmin = user$.sub === '66aa1d57-2c7b-4006-87c1-1f4d58507d50';
})

function userIsAdmin() {
    return isAdmin;
}

const routes = [
    {
        name: '/',
        component: Dashboard,
        layout: BaseLayout
    },
    {
        name: 'business-cards',
        component: BusinessCardsInfo,
        layout: PageLayout
    },
    {
        name: 'copying',
        component: HighVolumeCopyingInfo,
        layout: PageLayout
    },
    {
        name: 'flyers',
        component: FlyersInfo,
        layout: PageLayout
    },
    {
        name: 'manuals',
        component: TrainingManualsInfo,
        layout: PageLayout
    },
    {
        name: 'posters',
        component: PostersInfo,
        layout: PageLayout
    },
    {
        name: 'upload',
        component: Upload,
        layout: BaseLayout,
        onlyIf: { guard: userIsAdmin, redirect: '/' }
    },
    {
        name: 'login-cb',
        component: LoginCallback
    },
    {
        name: 'logout-cb',
        component: LogoutCallback
    }
];

export { routes };