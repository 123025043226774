<script>
  import { Route } from "svelte-router-spa";
  import Menu from "../components/menu.svelte";
  import Footer from "../components/footer.svelte";
  export let currentRoute;
  const params = {};
</script>

<style>

</style>

<div class="h-screen flex flex-col bg-white">
  <Menu />
  <section class="flex-auto overflow-y-auto">
    <Route {currentRoute} {params} />
    <Footer />
  </section>
</div>
