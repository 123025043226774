<script>
  let shake = false;
  let uploads = [];

  function handleMouseEnter(e) {
    e.preventDefault();
    e.stopPropagation();
    shake = true;
  }

  function handleMouseLeave(e) {
    e.preventDefault();
    e.stopPropagation();
    shake = false;
  }

  function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    shake = false;

    const files = [...e.dataTransfer.files].filter(
      d => d.type === "application/pdf"
    );
    if (files.length === 0) return;

    handleFiles(files);
  }

  function handleBrowse(e) {
    e.preventDefault();
    e.stopPropagation();

    const files = [...e.srcElement.files].filter(
      d => d.type === "application/pdf"
    );
    if (files.length === 0) return;

    e.srcElement.value = null;

    handleFiles(files);
  }

  function handleFiles(files) {
    console.log("FILES", files);

    files.forEach(d => {
      if (!uploads.find(m => m.name === d.name)) uploads = [...uploads, d];
    });
  }
</script>

<style>
  .fileover {
    animation: shake 1s;
    animation-iteration-count: infinite;
  }

  /* Shake animation */
  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }
</style>

<div
  class:fileover={shake}
  class="max-w-sm rounded shadow-lg relative bg-white border-dashed border
  border-brilliant-rose my-4 mx-auto p-4 text-center"
  on:dragover={handleMouseEnter}
  on:dragleave={handleMouseLeave}
  on:drop={handleDrop}>
  <input
    name="fileDrop"
    type="file"
    multiple
    class="absolute left-0 top-0 opacity-0 w-full h-full"
    on:change={handleBrowse}
    accept="application/pdf" />
  <img src="assets/dnd/ic-upload-file.svg" class="inline-block" />
  <h3 class="mt-4 font-semibold">Drag and drop file here</h3>
  <h3 class="font-semibold">or</h3>
  <label
    for="fileDrop"
    class="inline-block bg-brilliant-rose text-white rounded-l-full
    rounded-r-full py-1 px-4 my-2">
    Browse for file
  </label>
</div>

{#each uploads as item}
  <p>{item.name}</p>
{/each}
