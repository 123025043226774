<script>
  import { onMount, createEventDispatcher } from "svelte";
  import { localisedRoute, navigateTo, routeIsActive } from "svelte-router-spa";
  export let to = "/";
  export let title = "";
  export let styles = "";
  export let lang = null;

  const dispatch = createEventDispatcher();

  onMount(function() {
    if (lang) {
      const route = localisedRoute(to, lang);
      if (route) {
        to = route.path;
      }
    }
  });

  function navigate(event) {
    event.preventDefault();
    event.stopPropagation();
    navigateTo(to);
    dispatch("navigate", { to: to });
  }
</script>

<a
  href={to}
  {title}
  on:click={navigate}
  class={styles}>
  <slot />
</a>
