import { writable } from "svelte/store";

export const items = writable([
  { label: "Home", to: "/", active: false },
  // { label: "Upload", to: "upload" }
  { label: "Business Cards", to: "business-cards", active: false },
  { label: "Copying", to: "copying", active: false },
  { label: "Flyers", to: "flyers", active: false },
  { label: "Training Manuals", to: "manuals", active: false },
  { label: "Posters", to: "posters", active: false },
]);
