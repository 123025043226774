<script>
  import { createEventDispatcher, onMount, onDestroy } from "svelte";
  import { watchResize } from "svelte-watch-resize";

  const dispatch = createEventDispatcher();

  export let min = 0;
  export let max = 10;
  export let step = 1;
  export let value = 0;
  export let data;
  export let name;

  let element;
  let bead;
  let fill;
  let dragging = false;

  onMount(function () {
    document.addEventListener("mouseup", mouseUp);
    document.addEventListener("mousemove", mouseMove);

    if (value < min) value = min;
    if (value > max) value = max;
    
    const box = element.getBoundingClientRect();
    const pos = scaleToElement(value);
    bead.style.left = fill.style.width = Math.max(0, pos - box.x) + "px";

    dispatch("change", { value, data, name });
  });

  onDestroy(function () {
    document.removeEventListener("mouseup", mouseUp);
    document.removeEventListener("mousemove", mouseMove);
  });

  function mouseDown($event) {
    pauseEvent($event);
    dragging = true;
  }

  function mouseUp($event) {
    pauseEvent($event);
    dragging = false;
  }

  function mouseMove($event) {
    pauseEvent($event);
    if (!dragging) return;
    updateVisuals($event);
  }

  function mouseClick($event) {
    pauseEvent($event);
    updateVisuals($event);
  }

  function resize() {
    const box = element.getBoundingClientRect();
    const pos = scaleToElement(value);
    bead.style.left = fill.style.width = Math.max(0, pos - box.x) + "px";
  }

  function updateVisuals($event) {
    const box = element.getBoundingClientRect();
    const x = $event.clientX;
    value = Math.round(scaleToRange(x) / step) * step;
    if (value < min) value = min;
    if (value > max) value = max;

    const pos = scaleToElement(value);
    bead.style.left = fill.style.width = Math.max(0, pos - box.x) + "px";

    dispatch("change", { value, data, name });
  }

  function pauseEvent($event) {
    $event.stopPropagation();
    $event.preventDefault();
  }

  function scaleToElement(val) {
    const box = element.getBoundingClientRect();
    const minBox = box.x;
    const maxBox = box.width + box.x;
    const res = ((val - min) / (max - min)) * (maxBox - minBox) + minBox;

    return res;
  }

  function scaleToRange(pos) {
    const box = element.getBoundingClientRect();
    const minBox = box.x;
    const maxBox = box.width + box.x;
    const res = ((pos - minBox) / (maxBox - minBox)) * (max - min) + min;

    return res;
  }
</script>

<style>

</style>

<div
  bind:this={element}
  class="w-full"
  on:click={mouseClick}
  on:mousedown={mouseDown}
  use:watchResize={resize}>
  <div class="bg-gray-400 h-2 w-full rounded-full relative">
    <span
      bind:this={bead}
      class="bg-white h-4 w-4 absolute top-0 -ml-2 -mt-1 z-10 shadow
      rounded-full cursor-pointer" />
    <span
      bind:this={fill}
      class="bg-turquoise-blue-500 h-2 absolute left-0 top-0 rounded-full"
      style="width:0%" />
  </div>
  <div class="flex justify-between mt-2 text-xs text-gray-600">
    <span class="w-8 text-left">{min}</span>
    <span class="w-8 text-center text-lg text-brilliant-rose font-bold whitespace-no-wrap">{value}</span>
    <span class="w-8 text-right">{max}</span>
  </div>
</div>
