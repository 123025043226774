<script>
  import { Route } from "svelte-router-spa";
  import { loggedIn, user } from "../stores/user-store.js";
  import userService from "../services/user-service.js";
  import Navigate from "../components/navigate.svelte";
  import { onMount, onDestroy } from "svelte";
  import { items } from "../stores/menu-store.js";

  const params = {};

  userService.hydrate();

  function login() {
    userService.login();
  }

  function logout() {
    userService.logout();
  }

  let sidebarOpen = false;
  function toggleSidebar() {
    sidebarOpen = !sidebarOpen;
  }

  function handleNavigate() {
    updateMenuItems();
    if (!sidebarOpen) return;
    toggleSidebar();
  }

  function updateMenuItems() {
    items.update(arr => {
      return arr.map(d => {
        d.active = d.to === activeRoute();
        return d;
      });
    });
  }

  function activeRoute() {
    return window.location.pathname === "/"
      ? window.location.pathname
      : window.location.pathname.substr(1);
  }

  function clickOutside($event) {
    if (!!$event.target.closest(".sidebar")) return;
    if (!!$event.target.closest(".menu-toggle")) return;
    handleNavigate();
  }

  onMount(function() {
    document.addEventListener("click", clickOutside);
    updateMenuItems();
  });

  onDestroy(function() {
    document.removeEventListener("click", clickOutside);
  });
</script>

<style>
  .decorative-line {
    height: 4px;
  }

  .small-logo {
    max-width: 160px;
    max-height: 40px;
    width: auto;
    height: auto;
  }

  img.icon {
    padding: 0 0.5rem;
    width: 40px;
  }

  .sidebar {
    width: 270px;
    overflow-y: auto;
    overflow-x: hidden;
    transform: translateX(-110%);
    transition: 0.35s all cubic-bezier(0.65, 0.05, 0.36, 1);
  }

  .sidebar--open {
    transform: translateX(0);
  }

  .menubar {
    position: absolute;
    transform: translateY(-50%);
    margin-left: 4rem;
    width: calc(100vw - 8rem);
  }
</style>

<header>
  <div>
    <nav>
      <div
        class="md:hidden flex bg-white text-mine-shaft px-4 py-2 z-30 relative
        shadow-md">
        <img
          class="icon cursor-pointer menu-toggle"
          src="assets/zondicons/{sidebarOpen ? 'close' : 'menu'}.svg"
          alt=""
          on:click={toggleSidebar} />

        <Navigate to="/" on:navigate={handleNavigate}>
          <img
            class="small-logo ml-4"
            src="assets/logo/logo-default-274x62.png"
            alt=""
            width="274"
            height="62"
            srcset="assets/logo/logo-default-548x124.png 2x" />
        </Navigate>

        <div class="flex-auto" />

        <!-- <img
          class="icon"
          src="assets/zondicons/dots-horizontal-triple.svg"
          alt="" /> -->
      </div>

      <div
        class="hidden md:grid grid-cols-2 bg-mine-shaft text-white p-3 text-sm">
        <div class="text-left">
          Opening hours: Monday - Friday: 08:00 AM - 05:00 PM
        </div>
        <div class="text-right">
          <!-- {#if $loggedIn}
            Welcome back, {$user.given_name}!
            <a href="#" on:click={logout} class="underline hover:text-lemon">
              Logout
            </a>
          {:else}
            <a href="#" on:click={login} class="underline hover:text-lemon">
              Login
            </a>
          {/if} -->
        </div>
      </div>

      <div class="grid grid-cols-3">
        <div class="decorative-line bg-turquoise-blue" />
        <div class="decorative-line bg-brilliant-rose" />
        <div class="decorative-line bg-lemon" />
      </div>

      <div
        class="hidden md:flex justify-between items-center px-8 pt-4 pb-10
        bg-white">
        <div>
          <Navigate to="/" on:navigate={handleNavigate}>
            <img
              src="assets/logo/logo-default-274x62.png"
              alt=""
              width="274"
              height="62"
              srcset="assets/logo/logo-default-548x124.png 2x" />
          </Navigate>
        </div>
        <div>
          <ul class="flex items-center justify-center">
            <li class="px-8">
              <a
                href="https://www.google.com/maps/place/20+Southdale+Dr,+Southdale,+Johannesburg,+2091/@-26.2448551,28.0233047,18z/data=!3m1!4b1!4m5!3m4!1s0x1e950ed5e0c51fdb:0x420ce8908b53f939!8m2!3d-26.244857!4d28.0241608?hl=en"
                class="hover:text-brilliant-rose"
                target="blank">
                Unit 1, 20 Southdale Drive,
                <br />
                Southdale, Johannesburg 2001, South Africa
              </a>
            </li>
            <li class="pl-8 border-l">
              <a href="tel:+27766495133" class="hover:text-brilliant-rose">
                (+27) 076 649 5133
              </a>
              <br />
              <a
                href="mailto:mike@doubleimage.co.za?subject=Inquiry"
                class="hover:text-brilliant-rose"
                target="blank">
                mike@doubleimage.co.za
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div
        class="hidden md:block bg-mine-shaft rounded-l-full rounded-r-full px-8
        py-4 uppercase font-bold menubar z-10">
        {#each $items as item}
          <span
            class="mr-4 text-white transition duration-500 ease-in-out
            hover:text-lemon {item.active ? 'text-lemon' : ''}">
            <Navigate to={item.to} on:navigate={handleNavigate}>
              {item.label}
            </Navigate>
          </span>
        {/each}
      </div>

      <div
        class:sidebar--open={sidebarOpen}
        class="md:hidden sidebar bg-white fixed top-0 left-0 bottom-0 z-20
        shadow-md pt-16 flex flex-col items-center">

        <!-- <img
          class="h-16 m-2"
          src="assets/zondicons/user-solid-circle.svg"
          alt="" />

        {#if $loggedIn}
          <span>Welcome back, {$user.given_name}!</span>
          <a href="#" on:click={logout} class="underline hover:text-lemon">
            Logout
          </a>
        {:else}
          <a href="#" on:click={login} class="underline hover:text-lemon">
            Login
          </a>
        {/if} -->

        <ul class="w-full">
          {#each $items as item}
            <li class="mb-1">
              <Navigate
                to={item.to}
                on:navigate={handleNavigate}
                styles="block w-full hover:text-white hover:bg-lemon uppercase
                transition duration-200 ease-in-out font-semibold text-sm px-2
                py-1 {item.active ? 'text-brilliant-rose-500' : ''}">
                {item.label}
              </Navigate>
            </li>
          {/each}
        </ul>
      </div>
    </nav>
  </div>
</header>
