<script>
  import { onMount, onDestroy } from "svelte";
  import Navigate from "../components/navigate.svelte";
  import FileUpload from "../components/file-upload.svelte";
  import config from "../config.js";

  let files;
  let output = "";
  let formats = [
    { key: "azw3", value: "Amazon Kindle" },
    { key: "epub", value: "Bluefire Reader" }
  ];
  let format = "azw3";

  onMount(() => {});

  onDestroy(() => {});

  async function save() {
    var data = new FormData();
    data.append("file", files[0]);
    data.append("extension", format);

    const res = await fetch(`${config.uris.api}/files?format=json`, {
      method: "post",
      body: data
    });
    const json = await res.json();

    output = `File written to: ${json.output}`;
  }
</script>

<style>

</style>

<FileUpload />

<select bind:value={format}>
  {#each formats as item}
    <option value={item.key}>{item.value}</option>
  {/each}
</select>
<input type="file" bind:files />
<br />
<button
  class="bg-lemon hover:bg-lemon-700 text-black font-bold py-2 px-4 rounded"
  on:click={save}>
  Upload
</button>

{#if files && files[0]}
  <p>{files[0].name}</p>
  <p>{output}</p>
{/if}
