<script>
  import Navigate from "../components/navigate.svelte";

  export let src;
  export let styles = "";
  export let href = "#";
</script>

<style>
  .max-w-tile {
    height: 100%;
    max-width: 515px;
  }
  @media (max-width: 768px) {
    .max-w-tile {
      height: 100%;
      max-width: 269px;
    }
  }

  .max-w-tile-sm {
    height: 100%;
    max-width: 269px;
  }

  .zoom {
    transition: transform 0.5s ease;
  }

  .zoom:hover {
    transform: scale(1.025);
  }
</style>

<Navigate to={href} {styles}>
  <div class="block max-w-tile relative cursor-pointer zoom">
    <div class="h-full w-full rounded overflow-hidden shadow-lg flex flex-col">
      <img {src} class="flex-auto" />
      <div
        class="bg-mine-shaft text-white p-4 text-center text-xl font-semibold">
        <slot />
      </div>
    </div>
  </div>
</Navigate>
