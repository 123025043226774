export default {
    businessCards: {
        size: [
            { text: '85 x 50 mm', op: 'base', value: 100, default: true },
            { text: '90 x 50 mm', op: 'base', value: 100}
        ],
        material: [
            { text: '300gsm Matt Coated Board', op: 'mul', value: 1, default: true  },
            { text: '300gsm Gloss Coated Board', op: 'mul', value: 1 }
        ],
        sides: [
            { text: 'Single-sided', op: 'mul', value: 1, default: true },
            { text: 'Double-sided', op: 'mul', value: 1.5 }
        ],
        finishing: [
            { text: 'No luxury finishing (standard)', op: 'mul', value: 1, default: true },
            { text: '2-sided matt laminate', op: 'mul', value: 1.2 },
            { text: '2-sided gloss laminate', op: 'mul', value: 1.2 },
        ],
        quantity: {
            min: 200,
            max: 2000,
            step: 100
        }
    },
    copying: {
        size: [
            { text: 'A5', op: 'base', value: 15 },
            { text: 'A4', op: 'base', value: 30, default: true },
            { text: 'A3', op: 'base', value: 60}
        ],
        colour: [
            { text: 'Black and white prints', op: 'mul', value: 1, default: true  },
            { text: 'Full colour prints', op: 'mul', value: 3  }
        ],
        material: [
            { text: '80gsm Paper', op: 'mul', value: 1, default: true  }
        ],
        sides: [
            { text: 'Single-sided', op: 'mul', value: 1, default: true },
            { text: 'Double-sided', op: 'mul', value: 1.5 }
        ],
        finishing: [
            { text: 'No luxury finishing (standard)', op: 'mul', value: 1, default: true },
            { text: '2-hole or 4-hole punching', op: 'mul', value: 1.01 },
            { text: 'Folding and stapling', op: 'mul', value: 1.3 },
            { text: 'Corner stapling', op: 'mul', value: 1.1 },
        ],
        quantity: {
            min: 200,
            max: 2000,
            step: 100
        }
    },
    flyers: {
        size: [
            { text: 'A5', op: 'base', value: 15 },
            { text: 'A4', op: 'base', value: 30, default: true },
            { text: 'A3', op: 'base', value: 60}
        ],
        colour: [
            { text: 'Black and white prints', op: 'mul', value: 1, default: true  },
            { text: 'Full colour prints', op: 'mul', value: 3  }
        ],
        material: [
            { text: '80gsm Paper', op: 'mul', value: 1, default: true  },
            { text: '115gsm Matt Coated Paper', op: 'mul', value: 1.1  },
            { text: '115gsm Gloss Coated Paper', op: 'mul', value: 1.1  },
            { text: '128gsm Gloss Coated Paper', op: 'mul', value: 1.2  },
            { text: '128gsm Matt Coated Paper', op: 'mul', value: 1.2  },
            { text: '150gsm Gloss Coated Paper', op: 'mul', value: 1.3  },
            { text: '150gsm Matt Coated Paper', op: 'mul', value: 1.3  }
        ],
        sides: [
            { text: 'Single-sided', op: 'mul', value: 1, default: true },
            { text: 'Double-sided', op: 'mul', value: 1.5 }
        ],
        finishing: [
            { text: 'No luxury finishing (standard)', op: 'mul', value: 1, default: true },
            { text: '2-hole or 4-hole punching', op: 'mul', value: 1.01 },
            { text: 'Folding and stapling', op: 'mul', value: 1.3 },
            { text: 'Corner stapling', op: 'mul', value: 1.1 },
        ],
        quantity: {
            min: 200,
            max: 10000,
            step: 100
        }
    },
    manuals: {
        size: [
            { text: 'A5', op: 'base', value: 15 },
            { text: 'A4', op: 'base', value: 30, default: true },
            { text: 'A3', op: 'base', value: 60}
        ],
        colour: [
            { text: 'Black and white prints', op: 'mul', value: 1, default: true  },
            { text: 'Full colour prints', op: 'mul', value: 3  }
        ],
        material: [
            { text: '80gsm Paper', op: 'mul', value: 1, default: true  }
        ],
        sides: [
            { text: 'Single-sided', op: 'mul', value: 1, default: true },
            { text: 'Double-sided', op: 'mul', value: 1.5 }
        ],
        binding: [
            { text: 'Wiro binding with front and back cover', op: 'add', value: 300, default: true },
            { text: 'Wiro binding with cover encapsulation', op: 'add', value: 560 },
            { text: 'Cover encapsulation', op: 'add', value: 200 },
            { text: 'Folded and Stapled', op: 'add', value: 80 },
            { text: 'PVC Ring Binder', op: 'add', value: 5000 }
        ],
        dividers: [
            { text: 'No dividers', op: 'add', value: 0, default: true },
            { text: 'Printed dividers', op: 'add', value: 110, min: 1, max: 30 },
            { text: 'Plain dividers', op: 'add', value: 80, min: 1, max: 30 },
        ],
        pages: {
            min: 10,
            max: 1000,
            step: 1
        },
        quantity: {
            min: 10,
            max: 1000,
            step: 10
        }
    },
    posters: {
        size: [
            { text: 'A0', op: 'base', value: 440 },
            { text: 'A1', op: 'base', value: 240, default: true },
            { text: 'A2', op: 'base', value: 120},
            { text: 'A3', op: 'base', value: 60}
        ],
        colour: [
            { text: 'Full colour prints', op: 'mul', value: 70, default: true }
        ],
        material: [
            { text: '190gsm Matt Coated Paper', op: 'mul', value: 1, default: true  },
            { text: '190gsm Gloss Coated Paper', op: 'mul', value: 1  }
        ],
        quantity: {
            min: 1,
            max: 50,
            step: 1
        }
    }
}