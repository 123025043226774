const config = {
    auth: {
        // client: 'spa',
        client: 'dev',
        scopes: ['sample-api', 'openid', 'profile']
    },
    uris: {
        // id: 'http://192.168.8.111:30007',
        // ui: 'http://192.168.8.111:30008',
        // api: 'http://192.168.8.111:30006'
        id: 'http://localhost:5002',
        ui: 'http://localhost:5003',
        api: 'https://localhost:5001'
    }
};

export default config;