<script>
  import userService from "../services/user-service.js";

  userService.authorise();
</script>

<style>

</style>

<h1>Login Callback View</h1>
