<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { v4 as uuidv4 } from "uuid";

  export let label = "";
  export let name = "control";
  export let value = false;
  export let data;
  export let exclusive = false;

  let checkbox;
  let id = uuidv4();

  const dispatch = createEventDispatcher();

  onMount(() => {
    checkbox.checked = value;
    dispatch("change", { name, label, value, data });
  });

  function handleCheck($event) {
    $event.stopPropagation();
    $event.preventDefault();

    value = checkbox.checked;

    dispatch("change", { name, label, value, data });
  }
</script>

<style>
  .toggle-checkbox {
    outline: none;
    border-color: #cbd5e0;
  }

  .toggle-checkbox:checked {
    right: 0;
    border-color: #63ddec;
  }
  .toggle-checkbox:checked + .toggle-label {
    background-color: #63ddec;
  }
</style>

<div
  class="select-none relative inline-block w-10 mr-2 align-middle select-none
  transition duration-200 ease-in">
  <input
    bind:this={checkbox}
    on:change={handleCheck}
    type={exclusive ? 'radio' : 'checkbox'}
    {id}
    {name}
    class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4
    appearance-none cursor-pointer" />
  <label
    for={id}
    class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-400
    cursor-pointer" />
</div>
<label for={id} class="select-none text-s text-gray-700 cursor-pointer">
  {label}
</label>
