import querystring from "query-string";
import CryptoJS from "crypto-js";
import { navigateTo } from "svelte-router-spa";
import storageService from "./storage-service.js";
import { user } from "../stores/user-store.js";
import config from "../config.js";

function generateRandomString(length) {
  let text = "";
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

function generateCodeChallenge(codeVerifier) {
  return base64URL(CryptoJS.SHA256(codeVerifier));
}

function base64URL(str) {
  return str
    .toString(CryptoJS.enc.Base64)
    .replace(/=/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_");
}

export class UserService {
  async hydrate() {
    const token = this.getToken();
    if (!token) return;

    const userInfo = await this.getUserInfo();
    user.set(userInfo);
  }

  getCode() {
    return querystring.parse(location.search).code || null;
  }

  getCallbackError() {
    return querystring.parse(location.search).error || null;
  }

  getToken() {
    return storageService.read("token") || null;
  }

  generateCodes() {
    let codes = storageService.read("codes");
    if (!codes) {
      codes = {};
      codes.verifier = generateRandomString(128);
      codes.challenge = generateCodeChallenge(codes.verifier);
    }
    storageService.write("codes", codes, 300);

    return codes;
  }

  clear() {
    storageService.remove("token");
    storageService.remove("codes");
  }

  authoriseUri(challenge) {
    const qs = querystring.stringify({
      client_id: config.auth.client,
      redirect_uri: `${config.uris.ui}/login-cb`,
      scope: config.auth.scopes.join(" "),
      code_challenge: challenge,
      code_challenge_method: "S256",
      response_type: "code",
    });

    return `${config.uris.id}/connect/authorize?${qs}`;
  }

  async getUserInfo() {
    const token = this.getToken();
    if (!token) return;

    const response = await fetch(`${config.uris.id}/connect/userinfo`, {
      method: "post",
      headers: {
        Authorization: `${token.token_type} ${token.access_token}`,
      },
    });

    const json = await response.json();
    if (!response.ok) {
      throw json;
    }

    return json;
  }

  async authorise() {
    const cbError = this.getCallbackError();
    if (!!cbError) {
      navigateTo("/");
      return;
    }

    const code = this.getCode();
    const codes = this.generateCodes();
    const formData = new FormData();
    formData.append("client_id", "dev");
    formData.append("grant_type", "authorization_code");
    formData.append("redirect_uri", `${config.uris.ui}/login-cb`);
    formData.append("code_verifier", codes.verifier);
    formData.append("code", code);

    const response = await fetch(`${config.uris.id}/connect/token`, {
      method: "post",
      body: formData,
    });

    const json = await response.json();
    if (!response.ok) {
      throw json;
    }

    storageService.write("token", json, json.expires_in - 10);

    navigateTo("/");
  }

  login() {
    this.clear();

    const codes = this.generateCodes();
    const uri = this.authoriseUri(codes.challenge);

    window.location = uri;
  }

  logout() {
    const token = this.getToken();
    if (!token) return;

    const qs = {
      id_token_hint: token.id_token,
      post_logout_redirect_uri: `${config.uris.ui}/logout-cb`,
    };
    window.location = `${
      config.uris.id
    }/connect/endsession?${querystring.stringify(qs)}`;
  }
}

export default new UserService();
