import moment from "moment";

class StorageItem {
  constructor(content, duration, createdAt) {
    this.content = content;
    this.duration = duration;
    this.createdAt = createdAt;
    this.expiry = !!this.duration
      ? moment(this.createdAt).add(this.duration, "s")
      : null;
  }
}

class StorageService {
  constructor() {
    this.localStorageSupported =
      typeof window["localStorage"] !== "undefined" &&
      window["localStorage"] != null;
  }

  write(key, content, duration = 3600) {
    if (!this.localStorageSupported)
      throw new Error("LocalStorage is not supported");

    const item = new StorageItem(
      content,
      duration > 0 ? duration : null,
      moment()
    );
    const json = JSON.stringify(item);

    localStorage.setItem(key, json);
  }

  read(key) {
    if (!this.localStorageSupported)
      throw new Error("LocalStorage is not supported");

    const json = localStorage.getItem(key);
    if (!json) return null;

    const parsed = JSON.parse(json);
    parsed.createdAt = moment(parsed.createdAt);
    parsed.expiry = !!parsed.expiry ? moment(parsed.expiry) : null;

    const item = new StorageItem();
    Object.assign(item, parsed);

    if (!!item.expiry && moment().isAfter(item.expiry)) {
      localStorage.removeItem(key);
      return null;
    }

    this.write(key, item.content, item.duration);

    return item.content;
  }

  remove(key) {
    if (!this.localStorageSupported)
      throw new Error("LocalStorage is not supported");

    localStorage.removeItem(key);
  }
}

export default new StorageService();
