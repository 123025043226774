<script>
  import Button from "../components/button.svelte";
  import Navigate from "../components/navigate.svelte";
  import userService from "../services/user-service.js";
  import config from "../config.js";
  import { items } from "../stores/menu-store.js";

  let year = new Date().getFullYear();

  function updateMenuItems() {
    items.update(arr => {
      return arr.map(d => {
        d.active = d.to === activeRoute();
        return d;
      });
    });
  }

  function activeRoute() {
    return window.location.pathname === "/"
      ? window.location.pathname
      : window.location.pathname.substr(1);
  }
</script>

<style>
  .decorative-line {
    height: 4px;
  }

  section .decorative-line {
    width: 3rem;
  }
</style>

<footer>
  <div class="grid grid-cols-3">
    <div class="decorative-line bg-turquoise-blue" />
    <div class="decorative-line bg-brilliant-rose" />
    <div class="decorative-line bg-lemon" />
  </div>
  <section
    class="bg-mine-shaft-500 text-white grid grid-cols-1 sm:grid-cols-2
    lg:grid-cols-3">

    <div
      class="flex items-center lg:items-start flex-col p-4 pb-0 sm:p-8 sm:pb-0
      lg:p-16">
      <h5 class="uppercase text-xl font-semibold">Visit Us</h5>
      <div class="decorative-line bg-lemon my-4" />
      <svg
        class="fill-current text-mine-shaft-400 inline-block h-12 w-12"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20">
        <path d="M11 12h6v-1l-3-1V2l3-1V0H3v1l3 1v8l-3 1v1h6v7l1 1 1-1v-7z" />
      </svg>
      <a
        href="https://www.google.com/maps/place/20+Southdale+Dr,+Southdale,+Johannesburg,+2091/@-26.2448551,28.0233047,18z/data=!3m1!4b1!4m5!3m4!1s0x1e950ed5e0c51fdb:0x420ce8908b53f939!8m2!3d-26.244857!4d28.0241608?hl=en"
        class="hover:text-brilliant-rose"
        target="blank">
        Unit 1, 20 Southdale Drive,
        <br />
        Southdale, Johannesburg 2001, South Africa
      </a>
    </div>

    <div
      class="flex items-center lg:items-start flex-col p-4 pb-0 sm:p-8 sm:pb-0
      lg:p-16">
      <h5 class="uppercase text-xl font-semibold">Navigation</h5>
      <div class="decorative-line bg-brilliant-rose my-4" />
      <div class="w-full grid grid-cols-2">
        {#each $items as item}
          <span>
            <Navigate to={item.to} styles="hover:text-brilliant-rose" on:navigate={updateMenuItems}>
              {item.label}
            </Navigate>
          </span>
        {/each}
      </div>
    </div>

    <div
      class="flex items-center lg:items-start flex-col p-4 sm:col-span-2
      lg:col-span-1 sm:p-8 lg:p-16">
      <h5 class="uppercase text-xl font-semibold">Get in touch</h5>
      <div class="decorative-line bg-turquoise-blue my-4" />
      <p class="text-mine-shaft-300 text-center lg:text-left mb-4">
        We are always ready to answer any questions you may have regarding our
        services.
      </p>

      <div class="lg:hide">
        <a href="tel:+27766495133" class="hover:text-brilliant-rose">
          (+27) 076 649 5133
        </a>
        <br />
        <a
          href="mailto:mike@doubleimage.co.za?subject=Inquiry"
          class="hover:text-brilliant-rose"
          target="blank">
          mike@doubleimage.co.za
        </a>
      </div>
    </div>

  </section>
  <section
    class="bg-mine-shaft-700 text-white grid grid-cols-1 sm:grid-cols-2
    lg:grid-cols-3">
    <div />
    <div />
    <div class="py-4">
      <span>
        &copy; {year} Double Image. Design by
        <a href="https://zemez.io" target="blank">zemez</a>
      </span>
    </div>
  </section>
</footer>
